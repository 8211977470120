import { Component, OnInit } from '@angular/core';
import {
  AppEmbed,
  Page,
  AuthType,
  init,
  EmbedEvent,
  AuthStatus
} from '@thoughtspot/visual-embed-sdk';

@Component({
  selector: 'app-xonar-thoughtspot',
  templateUrl: './xonar-thoughtspot.component.html',
  styleUrls: ['./xonar-thoughtspot.component.scss'],
})
export class XonarThoughtspotComponent implements OnInit {

  // Update this value to reflect your environment's URL
  TS_URL = 'https://bridgenetsolutions.thoughtspot.cloud/';
  BASE_ENDPOINT = 'https://tspotcloud-api-prod.azurewebsites.net/api/ThoughtSpotFn/';
  USERNAME: string = null;

  constructor() {
    if (localStorage.getItem('username')) {
      this.USERNAME = localStorage.getItem('username');

      // ThoughtSpot Init with Cookie
      const authStatus = init({
        thoughtSpotHost: this.TS_URL,
        authType: AuthType.TrustedAuthToken,
        username: this.USERNAME,
        getAuthToken: () => {
          return fetch(`${this.BASE_ENDPOINT}?username=${this.USERNAME}`)
            .then((response) => response.json())
            .then((data) => data.token)
            .catch(error => console.log("Error:", error.message));
        }
      });
      authStatus.on(AuthStatus.FAILURE, (reason) => {
        console.log("Authentication failure type:", reason);
      })
    } else {
      console.log("Username is empty");
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    // ThoughtSpot App Embedding for iframe
    const appEmbed = new AppEmbed(document.getElementById('ts-embed'), {
      frameParams: {
        width: '100%',
        height: '90vh',
      },
      pageId: Page.Home,
      showPrimaryNavbar: true,
    });

    appEmbed
      .on(EmbedEvent.Init, showLoader)
      .on(EmbedEvent.Load, hideLoader)
      .on(EmbedEvent.AuthExpire, showAuthExpired)
      .render();

    // Function to show/hide
    function setDisplayStyle(el, style) {
      if (document.getElementById(el)) {
        document.getElementById(el).style.display = style;
      }
    }

    // Functions to show and hide a loader while iframe loads
    function showLoader() {
      setDisplayStyle('loader', 'block');
    }
    function hideLoader() {
      setDisplayStyle('loader', 'none');
    }

    function showAuthExpired() {
      setDisplayStyle('authExpiredBanner', 'flex');
    }
  }
}
