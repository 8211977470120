import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { InsightsService } from '../../../app-insights/insights.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit {
  constructor(
    private _router: Router,
    private _insights: InsightsService
  ) {}

  ngOnInit(): void {}

  sendHome(): void {
    this._router.navigateByUrl('/');
  }

  sendToProfile(): void {
    this._router.navigateByUrl('/profile');
    //this._insights.trackTrace({message: 'Profile button clicked'});
  }

  sendToReporting(): void {
    this._router.navigateByUrl('/reporting');
  }

  sendToThoughtspot(): void {
    this._router.navigateByUrl('/xonar-analytics');
  }
}
