<div class="home">
  <header class="banner row align-items-center py-5">
    <div class="col">
      <h1 class="mat-display-1 text-medium mb-2">
        {{ "homePage.title" | translate }}
      </h1>
      <p class="mat-body-1 text-medium-emphasis">
        {{ "homePage.subTitle" | translate }}
      </p>
    </div>
    <div class="col-auto">
      <mat-chip-list aria-label="Today date">
        <mat-chip class="px-3 py-2 mat-body-1">
          <mat-icon class="mr-2 md-18">today</mat-icon>
          {{ today | date : "MMM d, y, h:mm:ss a" | translate }}</mat-chip
        ></mat-chip-list
      >
    </div>
  </header>
  
  <section class="mb-4" *ngIf="isXonarAdmin">
    <div class="mat-headline text-semibold">
      {{ "homePage.applications" | translate }}
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 mb-4">
        <mat-card
          class="mat-elevation-z d-flex flex-column justify-content-between"
        >
          <div class="mat-subheading-2 mb-0">Xonar Control Tower</div>
          <mat-card-actions align="end">
            <a mat-icon-button color="primary" (click)="gotoXonarCT()">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </section>
  <section class="mb-5">
    <div class="mat-headline text-semibold">
      {{ "homePage.tools" | translate }}
    </div>
    <app-tool-card
      class="row"
      [isXonarUser]="isXonarUser"
      [isXonarAdmin]="isXonarAdmin"
    ></app-tool-card>
  </section>
</div>
