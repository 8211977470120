import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, catchError, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
// Services
import { IssuesReportService } from './issues-report.service';
import { InsightsService } from 'src/app/app-insights/insights.service';
// Material Components
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// Custom Components
import { IssuesReportKeyDialogComponent } from './issues-report-key-dialog/issues-report-key-dialog.component';

export interface Clients {
  client_id: string;
  client_name: string;
}

export interface IssueReport {
  issuesDateTime: string;
  ponumber: string;
  poVersion: string;
  issuesCategory: string;
  elementsFailed: string;
  vendorName: string;
  buyerName: string;
  buyerId: string;
  factoryCode: string;
  shipToCode: string;
  shipToName: string;
  shipToCity: string;
  shipToCountry: string;
}

interface issueType {
  issueValue: string;
  issueDesc: string;
}

@Component({
  selector: 'app-issues-report',
  templateUrl: './issues-report.component.html',
  styleUrls: ['./issues-report.component.scss'],
})
export class IssuesReportComponent implements OnInit, AfterViewInit, OnDestroy {
  // Update array once api (backend) is working
  clients: Clients[] = [
    {
      client_id: '1',
      client_name: 'Harman',
    },
  ];
  selectedTenant: any;
  form: FormGroup;
  link: string;
  isFileFound: boolean;
  isFetching: boolean = false;
  isDataFound: boolean = true;
  isExporttoExcel: boolean = false;
  isDataSelected: boolean;
  responseLength = 0;
  dataIssues: IssueReport[] = [];
  totalCount: number = 0;
  maxPage: number;
  isScrolling: boolean;
  isResizingRight: boolean;
  isLoading: boolean = false;
  pressed: boolean = false;
  isAuthenticated: boolean;
  startingNum: number;
  nextNum: number;

  public dataSource = new MatTableDataSource<IssueReport>();

  issuesReport: IssueReport[] = [];

  issueTypes: issueType[] = [
    { issueValue: '', issueDesc: 'All' },
    { issueValue: '403', issueDesc: '403-Forbidden' },
    { issueValue: '409', issueDesc: '409-Conflict' },
    { issueValue: '500', issueDesc: '500-Internal Server Error' },
    { issueValue: '1000', issueDesc: '1000-Invalid Incoterm' },
    { issueValue: '1001', issueDesc: '1001-Missing Firm Date' },
  ];

  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild('issuesReportTbSort') issuesReportTbSort = new MatSort();

  private _unsubscribeAll: Subject<void>;
  onIssuesReportChanged: BehaviorSubject<any>;
  pageNum: number = 1;

  filterReportFormGroup: FormGroup;
  range = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });

  currentResizeIndex: number;
  startX: number;
  startWidth: number;

  resizableMousemove: () => void;
  resizableMouseup: () => void;

  columns = [
    {
      columnDef: 'ponumber',
      header: 'PO No.',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'issuesDateTime',
      header: 'Issue Date',
      width: 15,
      index: 2,
    },
    {
      columnDef: 'issuesCategory',
      header: 'Issue Type',
      width: 15,
      index: 3,
    },
    {
      columnDef: 'elementsFailed',
      header: 'Elements Failed',
      width: 15,
      index: 4,
    },
    {
      columnDef: 'buyerName',
      header: 'Buyer',
      width: 15,
      index: 5,
    },
    {
      columnDef: 'vendorName',
      header: 'Vendor',
      width: 15,
      index: 6,
    },
    {
      columnDef: 'factoryCode',
      header: 'Factory Code',
      width: 15,
      index: 7,
    },
    {
      columnDef: 'shipToCode',
      header: 'Ship To Code',
      width: 15,
      index: 8,
    },
    {
      columnDef: 'shipToName',
      header: 'Ship To Name',
      width: 15,
      index: 9,
    },
    {
      columnDef: 'shipToCity',
      header: 'Ship To City',
      width: 15,
      index: 10,
    },
    {
      columnDef: 'shipToCountry',
      header: 'Ship To Country',
      width: 15,
      index: 11,
    },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);

  constructor(
    private _renderer: Renderer2,
    public dialog: MatDialog,
    private _IssuesReportService: IssuesReportService,
    private _formBuilder: FormBuilder,
    public _AuthService: AuthService,
    private _appInsightsService: InsightsService
  ) {
    this._unsubscribeAll = new Subject();
    this.onIssuesReportChanged = new BehaviorSubject({});
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.isScrolling = true;

    this.form = this._formBuilder.group({
      selectIssueCat: [''],
      //clientId: ['', Validators.required],
      tenantId: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    this.issuesReportTbSort.disableClear = true;
    this.dataSource.sort = this.issuesReportTbSort;
  }

  openDialog() {
    this.dialog.open(IssuesReportKeyDialogComponent, {
      width: '400px',
    });
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.isScrolling = false;
      const content = document.querySelector('.container');
      const doc = document.getElementById('container');
      const scrollTop = window.pageYOffset || doc.scrollTop;
      const scrollLeft = window.pageXOffset || doc.scrollLeft;
      window.scrollTo(scrollLeft, scrollTop);
      this.isFetching = true;
      this.pageNum = this.pageNum + 1;
      this.maxPage = Math.floor(this.totalCount / 20 + 1);

      if (this.pageNum <= this.totalCount / 20) {
        var startDate = this.form.get('startDate').value;
        var endDate = this.form.get('endDate').value;

        var start = this.form
          .get('startDate')
          .setValue(formatDate(startDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
        var end = this.form
          .get('endDate')
          .setValue(formatDate(endDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
        var startDateSelectedFormatted = this.form
          .get('startDate')
          .value.toString()
          .substring(0, 10);
        var endDateSelectedFormatted = this.form
          .get('endDate')
          .value.toString()
          .substring(0, 10);
        var issuesCategory = this.form.get('selectIssueCat').value;
        //var clientId = this.form.get("clientId").value;
        var clientId = '';

        this._IssuesReportService
          .getIssuesReport(
            20,
            this.pageNum,
            issuesCategory,
            startDateSelectedFormatted,
            endDateSelectedFormatted
          )
          .subscribe((response: any) => {
            this.issuesReport = response.issues;
            this.isFetching = false;
            this.isDataFound = true;
            this.dataSource.data = this.dataSource.data.concat(
              this.issuesReport
            ) as IssueReport[];
            this.isScrolling = true;
          });
      } else if (this.pageNum == this.maxPage) {
        var startDate = this.form.get('startDate').value;
        var endDate = this.form.get('endDate').value;

        var start = this.form
          .get('startDate')
          .setValue(formatDate(startDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
        var end = this.form
          .get('endDate')
          .setValue(formatDate(endDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
        var startDateSelectedFormatted = this.form
          .get('startDate')
          .value.toString()
          .substring(0, 10);
        var endDateSelectedFormatted = this.form
          .get('endDate')
          .value.toString()
          .substring(0, 10);
        var issuesCategory = this.form.get('selectIssueCat').value;
        //var clientId = this.form.get("clientId").value;

        this._IssuesReportService
          .getIssuesReport(
            20,
            this.pageNum,
            issuesCategory,
            startDateSelectedFormatted,
            endDateSelectedFormatted
          )
          .subscribe((response: any) => {
            this.issuesReport = response.issues;
            this.isFetching = false;
            this.dataSource.data = this.dataSource.data.concat(
              this.issuesReport
            ) as IssueReport[];
            this.isScrolling = true;
          });
      } else {
        this.isFetching = false;
        return;
      }
    }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.columnDef;
    });
  }

  onResizeColumn(event: any, index: number) {
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow =
      this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this._renderer.listen(
      'document',
      'mousemove',
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this._renderer.listen(
      'document',
      'mouseup',
      (event) => {
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName('mat-column-' + column.columnDef)
    );
    columnEls.forEach((el: Element) => {
      if (el instanceof HTMLElement) {
        el.style.width = column.width + 'px';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  searchIssuesReport(event) {
    this.isDataSelected = true;
    this.isFetching = true;
    this.isDataFound = true;

    var issuesCategory = this.form.get('selectIssueCat').value;
    var startDate = this.form.get('startDate').value;
    var endDate = this.form.get('endDate').value;

    if (!startDate || !endDate) {
      this.isDataSelected = false;
      this.isFetching = false;
      this.isExporttoExcel = false;
      this.totalCount = 0;
      console.log('Client, Date Range, or Issue Type not selected');

      const iss: IssueReport[] = [
        {
          issuesDateTime: '',
          ponumber: '',
          poVersion: '',
          issuesCategory: '',
          elementsFailed: '',
          vendorName: '',
          buyerName: '',
          buyerId: '',
          factoryCode: '',
          shipToCode: '',
          shipToName: '',
          shipToCity: '',
          shipToCountry: '',
        },
      ];
      this.issuesReport = iss;

      this.dataSource.data = this.issuesReport as IssueReport[];
    } else {
      this.isDataSelected = true;
      var start = this.form
        .get('startDate')
        .setValue(formatDate(startDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
      var end = this.form
        .get('endDate')
        .setValue(formatDate(endDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
      var startDateSelectedFormatted = this.form
        .get('startDate')
        .value.toString()
        .substring(0, 10);
      var endDateSelectedFormatted = this.form
        .get('endDate')
        .value.toString()
        .substring(0, 10);
      var issuesCategory = this.form.get('selectIssueCat').value;
      // var clientId = this.form.get("clientId").value;
      var clientId = '';

      this.getIssuesReport(
        issuesCategory,
        clientId,
        startDateSelectedFormatted,
        endDateSelectedFormatted
      );
    }
  }

  getIssuesReport(
    issuesCategory: string,
    clientId: string,
    startDate: string,
    endDate: string
  ) {
    this.isAuthenticated = true;
    this.isDataFound = true;
    this.isFetching = true;
    this.responseLength = 0;
    this.isExporttoExcel = false;

    const pageSize = 20;
    const currentPage = 1;
    this._IssuesReportService
      .getIssuesReport(
        pageSize,
        currentPage,
        issuesCategory,
        startDate,
        endDate
      )
      .subscribe((response: any) => {
        this.isExporttoExcel = true;
        if (response == false) {
          this.isFetching = false;
          this.isAuthenticated = false;
          this.isExporttoExcel = false;
          console.log(
            'User not autheticated to access the Issues Report data. Contact BNS Support.'
          );
          const iss: IssueReport[] = [
            {
              issuesDateTime: '',
              ponumber: '',
              poVersion: '',
              issuesCategory: '',
              elementsFailed: '',
              vendorName: '',
              buyerName: '',
              buyerId: '',
              factoryCode: '',
              shipToCode: '',
              shipToName: '',
              shipToCity: '',
              shipToCountry: '',
            },
          ];
          this.issuesReport = iss;
        } else {
          this.isFetching = false;
          this.issuesReport = response.issues;
          this.totalCount = response.totalCount;
          this.responseLength = this.issuesReport.length;
          if (this.responseLength === 0) {
            this.isDataFound = false;
            this.isExporttoExcel = false;
            console.log(
              'No results found.  Issues Report not functioning. Please try searching again.'
            );
            const iss: IssueReport[] = [
              {
                issuesDateTime: '',
                ponumber: '',
                poVersion: '',
                issuesCategory: '',
                elementsFailed: '',
                vendorName: '',
                buyerName: '',
                buyerId: '',
                factoryCode: '',
                shipToCode: '',
                shipToName: '',
                shipToCity: '',
                shipToCountry: '',
              },
            ];
            this.issuesReport = iss;
          }
        }

        this.dataSource.data = this.issuesReport as IssueReport[];

        this._AuthService.getUser().subscribe((data) => {
          var email = data.email;
          this._appInsightsService.logEvent(
            `Issues Report run successfully for ${startDate} and ${endDate}`,
            {
              'Email Id': email
            }
          );
        });
      }),
      catchError((err) => {
        this._appInsightsService.logException(err, 1);
        this.isDataFound = false;
        console.log('Handling error locally and rethrowing it...', err);
        throw new Error(err.status + ' Error : ' + err.statusText);
      });
  }

  exportAsExcel() {
    this.isDataFound = true;
    this.isFetching = true;

    var issuesCategory = this.form.get('selectIssueCat').value;

    var startDate = this.form.get('startDate').value;
    var endDate = this.form.get('endDate').value;

    var start = this.form
      .get('startDate')
      .setValue(formatDate(startDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var end = this.form
      .get('endDate')
      .setValue(formatDate(endDate, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var startDateSelectedFormatted = this.form
      .get('startDate')
      .value.toString()
      .substring(0, 10);
    var endDateSelectedFormatted = this.form
      .get('endDate')
      .value.toString()
      .substring(0, 10);
    var issuesCategory = this.form.get('selectIssueCat').value;
    //var clientId = this.form.get("clientId").value;
    var clientId = '';

    this._IssuesReportService
      .getReportsExport(
        issuesCategory,
        clientId,
        startDateSelectedFormatted,
        endDateSelectedFormatted
      )
      .subscribe((response: any) => {
        this.dataIssues = response;
        this.isFetching = false;

        this.getExcelExport(this.dataIssues);
      });
  }

  getExcelExport(issues: any) {
    try {
      const workSheet = XLSX.utils.json_to_sheet(this.dataIssues, { header: [] });
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
      XLSX.writeFile(workBook, 'issues-report.xlsx');

      this._AuthService.getUser().subscribe((data) => {
        var email = data.email;
        this._appInsightsService.logEvent(
          `Issues Report data exported successfully`,
          {
            'Email Id': email
          }
        );
      });
    } catch (e) {
      this._appInsightsService.logException(e.message, 1);
      console.error('Handling error locally and rethrowing it...', e.message);
    };
  }

  showEntityHistory(value: any) {
    if (value.ponumber == '' || null) {
      return;
    } else {
      console.log(value);
      let ponumber = value.ponumber;
      var url = '/reporting/po-entity-history-report?entityId=' + ponumber;
      window.open(url, '_blank');

      this._AuthService.getUser().subscribe((data) => {
        var email = data.email;
        this._appInsightsService.logEvent(
          `Redirected to Entity History successfully`,
          {
            'Email Id': email
          }
        );
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
