<div fxLayout fxLayoutAlign="center center">
  <button mat-button (click)="sendHome()">
    {{ "mainnav.home" | translate }}
  </button>
  <button mat-button (click)="sendToThoughtspot()">
    {{ "mainnav.analytics" | translate }}
  </button>
  <!-- functionalities not implemented -->
  <button mat-button [matMenuTriggerFor]="myToolsRef" class="d-none">
    <span>{{ "mainnav.myTools" | translate }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu hidden #myToolsRef="matMenu">
    <a [routerLink]="['/tools']" mat-menu-item>{{
      "mainnav.recent" | translate
    }}</a>
    <a [routerLink]="['/tools']" mat-menu-item>{{
      "mainnav.favorite" | translate
    }}</a>
  </mat-menu>
</div>
