import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import * as XLSX from 'xlsx';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
// Models
import { BnsPayloadType } from 'src/app/main/models/bnsPayloadType';
// Services
import { POEntityHistoryReportService } from './po-entity-history-report.service';
import { InsightsService } from 'src/app/app-insights/insights.service';
// Material Component
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// Custom Components
import { EntityHistoryKeyDialogComponent } from './entity-history-key-dialog/entity-history-key-dialog.component';
import { EntityHistoryStatusCodeKeyDialogComponent } from './entity-history-status-code-key-dialog/entity-history-status-code-key.component';
import { JsonVisualizerComponent } from 'src/app/shared/components/json-visualizer/json-visualizer.component';
import { EdiVisualizerDialogComponent } from 'src/app/shared/components/edi-visualizer/edi-visualizer.component';

export interface Data {
  createDateTime: string;
  entityType: string;
  statusCode: string;
  rspMsg: string;
}

export interface Clients {
  client_id: string;
  client_name: string;
}

export class EntityHistoryTx {
  id: number;
  seqId: number;
  tenantId: string;
  entityId: string;
  EntityType: BnsPayloadType;
  payload: object;
  createDateTime: Date;
  statusCode: string;
  rspMsg: string;
  fileId: string;
  isPayload: boolean;
}

@Component({
  selector: 'app-po-entity-history-report',
  templateUrl: './po-entity-history-report.component.html',
  styleUrls: ['./po-entity-history-report.component.scss'],
})
export class PoEntityHistoryReportComponent
  implements OnInit, AfterViewInit, OnDestroy {
  // Update array once api (backend) is working
  clients: Clients[] = [
    {
      client_id: '1',
      client_name: 'Harman',
    },
  ];
  selectedTenant: any;
  form: FormGroup;
  isDataFound: boolean;
  isDataSelected: boolean;
  isFetching: boolean = false;
  isExporttoExcel: boolean = false;
  responseLength = 0;
  public dataSource = new MatTableDataSource<EntityHistoryTx>();
  public dataSource1 = new MatTableDataSource<EntityHistoryTx>();
  entityHistory: EntityHistoryTx[] = [];
  startingNum: number = 0;
  nextNum: number = 20;
  entityHistoryArray: EntityHistoryTx[] = [];

  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild('poEntityHistoryReportTbSort') poEntityHistoryReportTbSort =
    new MatSort();
  private _unsubscribeAll: Subject<void>;
  onIssuesReportChanged: BehaviorSubject<any>;
  pageNum: number = 1;
  isScrolling: boolean;
  isFileInboundFound: boolean;
  isFileOutboundFound: boolean;
  entityId: string;

  pressed = false;
  currentResizeIndex: number;
  startX: number;
  startWidth: number;
  isResizingRight: boolean;
  isLoading = false;
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  columns = [
    {
      columnDef: 'createDateTime',
      header: 'Created',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'entityType',
      header: 'Entity Type',
      width: 15,
      index: 2,
    },
    {
      columnDef: 'statusCode',
      header: 'Status Code',
      width: 15,
      index: 3,
    },
    {
      columnDef: 'rspMsg',
      header: 'Rsp Msg',
      width: 15,
      index: 4,
    },
    {
      columnDef: 'payload',
      header: 'Payload',
      width: 15,
      index: 5,
    },
    {
      columnDef: 'fileId',
      header: 'Original File Reference',
      width: 15,
      index: 6,
    },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);

  constructor(
    private _renderer: Renderer2,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _EntityHistoryService: POEntityHistoryReportService,
    private route: ActivatedRoute,
    private _appInsightsService: InsightsService,
    public _AuthService: AuthService
  ) {
    this._unsubscribeAll = new Subject();
    this.onIssuesReportChanged = new BehaviorSubject({});
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.isScrolling = true;

    this.form = this._formBuilder.group({
      entityId: ['', Validators.required],
      clientId: ['', Validators.required],
      tradingPartnerId: ['', Validators.required],
    });

    this.route.queryParams.subscribe((params) => {
      console.log(params['entityId']);

      if (params['entityId'] != null) {
        this.handleRequestFromIssueReport(params['entityId']);
      }
    });
  }

  public handleRequestFromIssueReport(entityId) {
    if (entityId !== undefined) {
      this.form.controls['entityId'].setValue(entityId);
    }
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    this.poEntityHistoryReportTbSort.disableClear = true;
    this.dataSource.sort = this.poEntityHistoryReportTbSort;
  }

  openDialog() {
    this.dialog.open(EntityHistoryKeyDialogComponent, {
      width: '400px',
    });
  }

  openStatusCodeDialog() {
    this.dialog.open(EntityHistoryStatusCodeKeyDialogComponent, {
      width: '400px',
    });
  }


  onGetPayloadVisualizer(id: string) {
    this._EntityHistoryService.getPayload(id).subscribe((response: any) => {
      var json = JSON.parse(response.Payload);
      this.dialog.open(JsonVisualizerComponent, {
        width: '500px',
        data: json
      });
    });
  }

  onGetEDIVisualizer(id, payload) {
    this.isFileInboundFound = true;
    this.isFileOutboundFound = true;
    this.isFetching = true;

    this._EntityHistoryService.getEdiFile(id).subscribe((response: any) => {
      this.isFetching = false;
      let streamArray = response.stream;
      var decodedString = atob(streamArray);
      var formattedString = decodedString.replace(/~/g, '~\r\n');

      this.dialog.open(EdiVisualizerDialogComponent, {
        width: '800px',
        data: formattedString
      });
    });
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.isScrolling = false;
      const content = document.querySelector('.container');
      const doc = document.getElementById('container');
      const scrollTop = window.pageYOffset || doc.scrollTop;
      window.scrollTo(0, scrollTop);
      this.isFetching = true;

      if (this.nextNum <= this.responseLength - 20) {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.startingNum + 20;

        this.entityHistoryArray = this.entityHistory.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.entityHistoryArray
        ) as EntityHistoryTx[];
        this.isFetching = false;
      } else if (this.nextNum == this.responseLength) {
        this.isFetching = false;
        return;
      } else if (this.responseLength <= 20) {
        this.isFetching = false;
        return;
      } else {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.responseLength;

        this.entityHistoryArray = this.entityHistory.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.entityHistoryArray
        ) as EntityHistoryTx[];
        this.isFetching = false;
      }

      this.isScrolling = true;
      this.isFetching = false;
    }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.columnDef;
    });
  }

  onResizeColumn(event: any, index: number) {
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow =
      this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this._renderer.listen(
      'document',
      'mousemove',
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this._renderer.listen(
      'document',
      'mouseup',
      (event) => {
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName('mat-column-' + column.columnDef)
    );
    columnEls.forEach((el: Element) => {
      if (el instanceof HTMLElement) {
        el.style.width = column.width + 'px';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  SearchEntity() {
    this.isDataFound = true;
    this.isFetching = true;
    this.isFileInboundFound = true;
    this.isDataSelected = true;
    var entityselected = this.form.get('entityId').value;
    var sourceTradingPartnerId = this.form.get('tradingPartnerId').value;
    if (entityselected === '') {
      this.isDataSelected = false;
      this.isFetching = false;
      this.isExporttoExcel = false;
      this.responseLength = 0;
      console.log('Client or Entity ID not selected');

      const eh: EntityHistoryTx[] = [
        {
          id: null,
          seqId: null,
          tenantId: null,
          entityId: null,
          EntityType: null,
          payload: null,
          createDateTime: null,
          statusCode: '',
          rspMsg: '',
          fileId: '',
          isPayload: null,
        },
      ];

      this.entityHistory = eh;

      this.startingNum = 0;
      this.nextNum = 20;

      this.dataSource.data = this.entityHistory.slice(
        this.startingNum,
        this.nextNum
      ) as EntityHistoryTx[];
    } else {
      this.isDataSelected = true;
      this.onGetEntityHistory('00100-00100-00100', entityselected);
    }
  }

  onGetEntityHistory(selectedTenantId: string, selectedEntityId: string) {
    this.isExporttoExcel = false;
    this._EntityHistoryService
      .getEntityHistoryReport(selectedTenantId, selectedEntityId, false)
      .subscribe((response: any) => {
        this.isFetching = false;
        this.entityHistory = response;
        this.isExporttoExcel = true;
        this.responseLength = this.entityHistory.length;
        this.isDataFound = true;
        if (this.entityHistory && this.responseLength === 0) {
          this.isDataFound = false;
          this.isExporttoExcel = false;
          console.log(
            'No results found.  Please make sure you are entering the correct PO number.'
          );

          const eh: EntityHistoryTx[] = [
            {
              id: null,
              seqId: null,
              tenantId: null,
              entityId: null,
              EntityType: null,
              payload: null,
              createDateTime: null,
              statusCode: '',
              rspMsg: '',
              fileId: '',
              isPayload: null,
            },
          ];

          this.entityHistory = eh;
        }

        this.startingNum = 0;
        this.nextNum = 20;

        this.dataSource.data = this.entityHistory.slice(
          this.startingNum,
          this.nextNum
        ) as EntityHistoryTx[];

        this._AuthService.getUser().subscribe((data) => {
          var email = data.email;
          this._appInsightsService.logEvent(
            `PO Entity History Report run successfully for entity ID ${selectedEntityId}`,
            {
              'Email Id': email
            }
          );
        });
      }),
      (error) => {
        this._appInsightsService.logException(error, 1);
        this.isDataFound = false;
        console.error(error.StatusCode.ToString());
      };
  }

  onCopyJsonToClipboard(id: string, entityId: string) {
    this._EntityHistoryService.getPayload(id).subscribe((response: any) => {
      var element = document.createElement('a');
      const blob = new Blob([response.Payload], { type: 'text' });
      element.href = window.URL.createObjectURL(blob);
      element.download = entityId + ' payload';
      document.body.appendChild(element);
      element.click();

      document.body.removeChild(element);

      this._AuthService.getUser().subscribe((data) => {
        var email = data.email;
        this._appInsightsService.logEvent(
          `PO Entity History Report payload downloaded successfully`,
          {
            'Email Id': email
          }
        );
      });
    }),
    (error) => {
      this._appInsightsService.logException(error, 1);
      console.error(error.StatusCode.ToString());
    };
  }

  exportAsExcel() {
    this.isDataFound = true;
    this.isFetching = true;
    var entityselected = this.form.get('entityId').value;

    this._EntityHistoryService
      .getEntityHistoryReportExport('00100-00100-00100', entityselected, true)
      .subscribe((response: any) => {
        this.isFetching = false;
        if (response == null) {
          this.isDataFound = false;
          this.entityHistory = [];
          console.log(
            'No results found.  Please make sure you are entering the correct PO number.'
          );
        } else if (response != null) {
          this.entityHistory = response;
          this.responseLength = this.entityHistory.length;
          if (response == null || this.responseLength === 0) {
            this.isDataFound = false;
            console.log(
              'No results found.  Please make sure you are entering the correct PO number.'
            );
          }
        }

        this.dataSource1.data = this.entityHistory as EntityHistoryTx[];

        var heading = [
          [
            'ID',
            'SeqId',
            'Tenant ID',
            'Entity ID',
            'Entity Type',
            'Payload',
            'Create Date Time',
            'Status Code',
            'Rsp Msg',
            'File ID',
            'IsPayload'
          ],
        ];

        var wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(this.dataSource1.data, {
          skipHeader: true,
        });
        for(var i=0; i <= this.dataSource1.data.length; i++)
        {
          var v = 'K'+(i+1);
          delete(ws[v]);

        }

        const wss = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(wss, heading);

        XLSX.utils.sheet_add_json(wss, this.dataSource1.data, {
          origin: 'A2',
          skipHeader: true,
        });

        for(var i=0; i <= this.dataSource1.data.length; i++)
        {
          var v = 'K'+(i+1);
          delete(wss[v]);

        }

        XLSX.utils.book_append_sheet(wb, wss, 'SheetName');

        var fileName = 'entity-history-report.xlsx';

        XLSX.writeFile(wb, fileName);

        this._AuthService.getUser().subscribe((data) => {
          var email = data.email;
          this._appInsightsService.logEvent(
            `PO Entity History Report data exported successfully`,
            {
              'Email Id': email
            }
          );
        });
      }),
      (error) => {
        this._appInsightsService.logException(error, 1);
        this.isDataFound = false;
        console.error(error.StatusCode.ToString());
      };
  }

  onDownloadEDI(id: number) {
    this.isFileInboundFound = true;
    this.isFileOutboundFound = true;
    this.isFetching = true;

    this._EntityHistoryService.getEdiFile(id).subscribe((response: any) => {
      this.isFetching = false;
      let streamArray = response.stream;
      let fileName = response.fileName;
      var decodedString = atob(streamArray);
      var decodedFileName = atob(fileName);

      if (decodedString == '' && decodedFileName == '') {
        this.isFileInboundFound = false;
        console.log(
          'No EDI file found in blob storage. Check inbound and inbound-archive storage containers.'
        );
      } else {
        this.isFileInboundFound = true;
        var element = document.createElement('a');
        const blob = new Blob([decodedString], { type: 'text' });
        element.href = window.URL.createObjectURL(blob);
        element.download = decodedFileName;
        document.body.appendChild(element);
        element.click();

        document.body.removeChild(element);

        this._AuthService.getUser().subscribe((data) => {
          var email = data.email;
          this._appInsightsService.logEvent(
            `PO Entity History Report inbound EDI file downloaded successfully`,
            {
              'Email Id': email
            }
          );
        });
      }
    }),
      (error) => {
        this._appInsightsService.logException(error, 1);
        console.error(error.StatusCode.ToString());
      };
  }

  isOverflow(el: HTMLElement): boolean {
    var curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
    var isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
