<div class="table-wrapper">
  <header class="table-header">
    <div fxLayout="row" fxLayoutAlign="center center" class="mb-3">
      <span class="mat-title text-uppercase mb-0">{{
        "issuesReport.reportTitle" | translate
      }}</span>
      <mat-icon
        aria-hidden="false"
        aria-label="about-report"
        class="md-18 ml-2 medium-emphasis"
        [matTooltip]="'issuesReport.aboutTooltip' | translate"
      >
        info
      </mat-icon>
      <app-reports-menu class="ml-1"></app-reports-menu>
    </div>

    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ "issuesReport.client" | translate }}</mat-label>
          <mat-select disableOptionCentering>
            <mat-option
              *ngFor="let client of clients"
              [value]="client.client_id"
            >
              {{ client.client_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>{{
            "issuesReport.chooseDateRange" | translate
          }}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              formControlName="startDate"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="endDate"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="issue-type-dropdown ml-2">
          <mat-label>{{ "issuesReport.issueType" | translate }}</mat-label>
          <mat-select
            formControlName="selectIssueCat"
            #filterByIssue
            disableOptionCentering
          >
            <mat-option
              *ngFor="let issueType of issueTypes"
              [value]="issueType.issueValue"
            >
              {{ issueType.issueDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button type="button" class="help-button" (click)="openDialog()">
          <mat-icon
            aria-hidden="false"
            aria-label="help-button"
            class="medium-emphasis"
            [matTooltip]="'issuesReport.helpTooltip' | translate"
          >
            help_outline
          </mat-icon>
        </button>
        <button mat-stroked-button aria-label="search-button" (click)="searchIssuesReport($event)">
          {{ "issuesReport.search" | translate }}
        </button>
        <button
          type="button"
          mat-flat-button
          class="ml-2"
          color="primary"
          (click)="exportAsExcel()"
          [disabled]="!isExporttoExcel"
          [matTooltip]="'issuesReport.exportTooltip' | translate"
        >
          <mat-icon>file_download</mat-icon>
          {{ "issuesReport.export" | translate }}
        </button>
      </form>
    </div>
  </header>

  <mat-progress-bar
    *ngIf="isFetching"
    mode="indeterminate"
    value="40"
  ></mat-progress-bar>

  <section class="banner-wrapper">
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "issuesReport.noDataFound" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataSelected == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >report_problem</mat-icon
        >
        {{ "issuesReport.noDataSelected" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isAuthenticated == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "issuesReport.unautheticatedUser" | translate }}
      </div>
    </div>
  </section>

  <section class="container-fluid py-3">
    <div
      id="container"
      [ngClass]="[isScrolling ? 'content-card' : 'content-card-hidden']"
      (scroll)="onScroll($event)"
      class="table-content"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        #issuesReportTbSort="matSort"
      >
        <!-- PO Number  -->
        <ng-container matColumnDef="ponumber">
          <th mat-header-cell *matHeaderCellDef>
            PO No
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-primary">{{ row.ponumber }}</div>
          </td>
        </ng-container>

        <!-- Issues Date Time -->
        <ng-container matColumnDef="issuesDateTime">
          <th mat-header-cell *matHeaderCellDef>
            Issue Date
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 2)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.issuesDateTime | date : "yyyy-MM-dd HH:mm:ss" }}
          </td>
        </ng-container>

        <!-- Issue Category  -->
        <ng-container matColumnDef="issuesCategory">
          <th mat-header-cell *matHeaderCellDef>
            Issue Category
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 3)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-2-lines">
              {{ row.issuesCategory }}
            </div>
          </td>
        </ng-container>

        <!-- Elements Failed  -->
        <ng-container matColumnDef="elementsFailed">
          <th mat-header-cell *matHeaderCellDef>
            Elements Failed
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 4)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-2-lines">
              {{ row.elementsFailed }}
            </div>
          </td>
        </ng-container>

        <!-- Buyer  -->
        <ng-container matColumnDef="buyerName">
          <th mat-header-cell *matHeaderCellDef>
            Buyer
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 5)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.buyerName }}
          </td>
        </ng-container>

        <!-- Vendor  -->
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef>
            Vendor
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 6)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-2-lines">
              {{ row.vendorName }}
            </div>
          </td>
        </ng-container>

        <!-- Factory Code  -->
        <ng-container matColumnDef="factoryCode">
          <th mat-header-cell *matHeaderCellDef>
            Factory Code
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 7)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.factoryCode }}
          </td>
        </ng-container>

        <!-- Ship To Code  -->
        <ng-container matColumnDef="shipToCode">
          <th mat-header-cell *matHeaderCellDef>
            Ship To Code
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 8)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.shipToCode }}
          </td>
        </ng-container>

        <!-- Ship To Name  -->
        <ng-container matColumnDef="shipToName">
          <th mat-header-cell *matHeaderCellDef>
            Ship To Name
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 9)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-2-lines">
              {{ row.shipToName }}
            </div>
          </td>
        </ng-container>

        <!-- Ship To City  -->
        <ng-container matColumnDef="shipToCity">
          <th mat-header-cell *matHeaderCellDef>
            Ship To City
            <span
              class="resize-handle"
              (mousedown)="onResizeColumn($event, 10)"
            >
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.shipToCity }}
          </td>
        </ng-container>

        <!-- Ship To Country  -->
        <ng-container matColumnDef="shipToCountry">
          <th mat-header-cell *matHeaderCellDef>
            Ship To Country
            <span
              class="resize-handle"
              (mousedown)="onResizeColumn($event, 11)"
            >
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.shipToCountry }}
          </td>
        </ng-container>

        <!-- Total Count -->
        <ng-container matColumnDef="totalCount">
          <td
            mat-footer-cell
            *matFooterCellDef
            [attr.colspan]="displayedColumns.length"
          >
            <span class="text-medium"
              >{{ "issuesReport.totalCount" | translate }}
              {{ this.totalCount }}</span
            >
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="showEntityHistory(row)"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="['totalCount']; sticky: true"></tr>
      </table>
    </div>
  </section>
</div>
