import { PoEntityHistoryReportComponent } from './apps/reporting/po-entity-history-report/po-entity-history-report.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './main/pages/home/home.component';
import { XonarThoughtspotComponent } from './main/pages/xonar-thoughtspot/xonar-thoughtspot.component';
import { LoginComponent } from './main/pages/login/login.component';
import { GenericErrorComponent } from './main/pages/generic-error/generic-error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { LayoutFixedComponent } from './layouts/layout-fixed/layout-fixed.component';
import { LayoutFullComponent } from './layouts/layout-full/layout-full.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { ProfileComponent } from './main/pages/profile/profile.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutFixedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
       data: { title: 'Home Page', claimName: 'canAccessReports' },
      },
      {
        path: 'generic-error',
        component: GenericErrorComponent,
        pathMatch: 'full',
        data: { title: 'Generic Error Page' },
      },
    ],
  },
  {
    path: '',
    component: LayoutFullComponent,
    children: [
      {
        path: 'reporting',
        loadChildren: () => import('src/app/apps/reporting/reporting-routing.module').then(m => m.ReportingRoutingModule),
      },
      {
        path: 'client-provisioning',
        loadChildren: () => import('src/app/apps/client-provisioning/client-provisioning-routing.module').then(m => m.ClientRoutingModule),
      },
      {
        path: 'profile',
        component: ProfileComponent,
        pathMatch: 'full',
        data: {
          title: 'Profile',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'xonar-analytics',
        component: XonarThoughtspotComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: { title: 'Xonar Analytics Page' },
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Login', claimName: 'canAccessLoginPage' },
  },
  {
    path: '**',
    component: GenericErrorComponent,
    data: { title: '404' }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  declarations: [LoadingComponent],
  exports: [RouterModule, LoadingComponent],
})
export class AppRoutingModule { }
