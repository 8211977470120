import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import * as XLSX from 'xlsx';
import { BehaviorSubject, catchError, Subject } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { formatDate } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
// Services
import { InsightsService } from 'src/app/app-insights/insights.service';
import { LspEdiErrorReportService } from './lsp-edi-error-report.service';
// Material Component
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
// Custom Components
import { LspEdiErrorReportKeyDialogComponent } from './lsp-edi-error-report-key-dialog/lsp-edi-error-report-key-dialog.component';
import { JsonVisualizerComponent } from 'src/app/shared/components/json-visualizer/json-visualizer.component';
import { EdiVisualizerDialogComponent } from 'src/app/shared/components/edi-visualizer/edi-visualizer.component';


export class LspEdiErrorTx {
  lsp: string;
  housebill: string;
  containerNumber: string;
  endpoint: string;
  jsonObject: object;
  mappedToObject: object;
  fileReceivedTime: Date;
  originalFileReference: string;
  errorMessage: string;
  isPayload: boolean;
}

@Component({
  selector: 'app-lsp-edi-error-report',
  templateUrl: './lsp-edi-error-report.component.html',
  styleUrls: ['./lsp-edi-error-report.component.scss'],
})
export class LspEdiErrorReportComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild('lspEdiErrorReportTbSort')
  lspEdiErrorReportTbSort = new MatSort();

  isFileFound: boolean;
  isFetching: boolean = false;
  isExporttoExcel: boolean = false;
  isDataSelected: boolean = true;
  isDataFound: boolean;
  isRawFileFound: boolean;
  responseLength = 0;
  public dataSource = new MatTableDataSource<LspEdiErrorTx>();
  lspEdiError: LspEdiErrorTx[] = [];
  dataIssues: LspEdiErrorTx[] = [];
  lspEdiErrorArray: LspEdiErrorTx[] = [];
  totalCount: number;
  startingNum: number = 0;
  nextNum: number = 20;
  isAuthenticated: boolean;
  private _unsubscribeAll: Subject<void>;
  onIssuesReportChanged: BehaviorSubject<any>;
  pageNum: number = 1;
  isScrolling: boolean;

  test: boolean;

  pressed = false;
  currentResizeIndex: number;
  startX: number;
  startWidth: number;
  isResizingRight: boolean;
  isLoading = false;
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  // form
  form: FormGroup;
  range = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });

  columns = [
    {
      columnDef: 'lsp',
      header: 'LSP',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'housebill',
      header: 'House Bill',
      width: 15,
      index: 2,
    },
    {
      columnDef: 'containerNumber',
      header: 'Container Number',
      width: 15,
      index: 3,
    },
    {
      columnDef: 'endpoint',
      header: 'Endpoint',
      width: 15,
      index: 4,
    },
    {
      columnDef: 'jsonObject',
      header: 'Json Object',
      width: 15,
      index: 5,
    },
    {
      columnDef: 'mappedToObject',
      header: 'Mapped To Object',
      width: 15,
      index: 6,
    },
    {
      columnDef: 'fileReceivedTime',
      header: 'File Received Time',
      width: 15,
      index: 7,
    },
    {
      columnDef: 'originalFileReference',
      header: 'Original File Ref',
      width: 15,
      index: 8,
    },
    {
      columnDef: 'errorMessage',
      header: 'Error Message',
      width: 15,
      index: 9,
    },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);

  constructor(
    private _renderer: Renderer2,
    private _formBuilder: FormBuilder,
    private _LspEdiErrorReportService: LspEdiErrorReportService,
    public dialog: MatDialog,
    private _appInsightsService: InsightsService,
    public _AuthService: AuthService
  ) {
    this._unsubscribeAll = new Subject();
    this.onIssuesReportChanged = new BehaviorSubject({});
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.isScrolling = true;

    this.form = this._formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    this.lspEdiErrorReportTbSort.disableClear = true;
    this.dataSource.sort = this.lspEdiErrorReportTbSort;
  }

  openDialog() {
    this.dialog.open(LspEdiErrorReportKeyDialogComponent, {
      width: '400px',
    });
  }

  openJsonDialog(tableId: string) {
    var startDateSelected = this.form.get('startDate').value;
    var endDateSelected = this.form.get('endDate').value;
    var start = this.form
      .get('startDate')
      .setValue(formatDate(startDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var end = this.form
      .get('endDate')
      .setValue(formatDate(endDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var startDateSelectedFormatted = this.form
      .get('startDate')
      .value.toString()
      .substring(0, 10);
    var endDateSelectedFormatted = this.form
      .get('endDate')
      .value.toString()
      .substring(0, 10);

    this._LspEdiErrorReportService.getPayload(startDateSelectedFormatted, endDateSelectedFormatted, tableId).subscribe((response: any) => {
      var json = JSON.parse(response.payload);
      this.dialog.open(JsonVisualizerComponent, {
        width: '500px',
        data: json
      });
    });
  }

  openFileRefDialog(fileName: string) {
    this._LspEdiErrorReportService
      .onDownloadEDI(fileName)
      .subscribe((response: any) => {
        let streamArray = response.stream;
        var decodedString = atob(streamArray);
        var formattedString = decodedString.replace(/~/g, '~\r\n');

        this.dialog.open(EdiVisualizerDialogComponent, {
          width: '800px',
          data: formattedString
        });

      });

  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.isScrolling = false;
      const content = document.querySelector('.container');
      const doc = document.getElementById('container');
      const scrollTop = window.pageYOffset || doc.scrollTop;
      window.scrollTo(0, scrollTop);
      this.isFetching = true;
      if (this.nextNum <= this.responseLength - 20) {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.startingNum + 20;

        this.lspEdiErrorArray = this.lspEdiError.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.lspEdiErrorArray
        ) as LspEdiErrorTx[];
        this.isFetching = false;
      } else if (this.nextNum == this.responseLength) {
        this.isFetching = false;
        return;
      } else {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.responseLength;

        this.lspEdiErrorArray = this.lspEdiError.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.lspEdiErrorArray
        ) as LspEdiErrorTx[];
        this.isFetching = false;
      }
      this.isScrolling = true;
      this.isFetching = false;
    }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.columnDef;
    });
  }

  onResizeColumn(event: any, index: number) {
    console.log(event.target.parentElement);
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow =
      this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this._renderer.listen(
      'document',
      'mousemove',
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this._renderer.listen(
      'document',
      'mouseup',
      (event) => {
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName('mat-column-' + column.columnDef)
    );
    columnEls.forEach((el: Element) => {
      if (el instanceof HTMLElement) {
        el.style.width = column.width + 'px';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  searchLspEdiErrorReport() {
    this.isDataFound = true;
    this.isFetching = true;
    this.isFileFound = true;
    this.isRawFileFound = true;
    this.isDataSelected = true;

    var startDateSelected = this.form.get('startDate').value;
    var endDateSelected = this.form.get('endDate').value;

    if (!startDateSelected || !endDateSelected) {
      this.isDataSelected = false;
      this.isFetching = false;
      this.isExporttoExcel = false;
      this.responseLength = 0;
      console.log('Date range is not selected');

      const ef: LspEdiErrorTx[] = [
        {
          lsp: '',
          housebill: '',
          containerNumber: '',
          endpoint: '',
          jsonObject: null,
          mappedToObject: null,
          fileReceivedTime: null,
          originalFileReference: '',
          errorMessage: '',
          isPayload: null
        },
      ];

      this.lspEdiError = ef;

      this.startingNum = 0;
      this.nextNum = 20;
      this.dataSource.data = this.lspEdiError as LspEdiErrorTx[];
    } else {
      this.isDataSelected = true;
      var start = this.form
        .get('startDate')
        .setValue(formatDate(startDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
      var end = this.form
        .get('endDate')
        .setValue(formatDate(endDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
      var startDateSelectedFormatted = this.form
        .get('startDate')
        .value.toString()
        .substring(0, 10);
      var endDateSelectedFormatted = this.form
        .get('endDate')
        .value.toString()
        .substring(0, 10);

      this.onGetLspEdiErrorReportPaginated(
        startDateSelectedFormatted,
        endDateSelectedFormatted
      );
    }
  }

  onGetLspEdiErrorReportPaginated(startDate: string, endDate: string) {
    this.isExporttoExcel = false;
    this._LspEdiErrorReportService
      .getLspEdiErrorReport(startDate, endDate)
      .subscribe((response: any) => {
        if (response == false) {
          this.isFetching = false;
          this.isAuthenticated = false;
          console.log(
            'User not authenticated to access the LSP EDI Error Report data. Contact BNS Support.'
          );

          const ef: LspEdiErrorTx[] = [
            {
              lsp: '',
              housebill: '',
              containerNumber: '',
              endpoint: '',
              jsonObject: null,
              mappedToObject: null,
              fileReceivedTime: null,
              originalFileReference: '',
              errorMessage: '',
              isPayload: null
            },
          ];

          this.lspEdiError = ef;
        } else if (!response.issues) {
          this.isFetching = false;
          this.isDataFound = false;
          console.log(
            'No results found.  Start/End dates are invalid and return no data.'
          );
          const ef: LspEdiErrorTx[] = [
            {
              lsp: '',
              housebill: '',
              containerNumber: '',
              endpoint: '',
              jsonObject: null,
              mappedToObject: null,
              fileReceivedTime: null,
              originalFileReference: '',
              errorMessage: '',
              isPayload: null
            },
          ];

          this.lspEdiError = ef;
        } else {
          this.isFetching = false;
          this.isExporttoExcel = true;
          this.lspEdiError = response.issues;
          this.responseLength = this.lspEdiError.length;
          if (this.lspEdiError && this.lspEdiError.length === 0) {
            this.isDataFound = false;
            this.isExporttoExcel = false;

            const ef: LspEdiErrorTx[] = [
              {
                lsp: '',
                housebill: '',
                containerNumber: '',
                endpoint: '',
                jsonObject: null,
                mappedToObject: null,
                fileReceivedTime: null,
                originalFileReference: '',
                errorMessage: '',
                isPayload: null
              },
            ];

            this.lspEdiError = ef;

            console.log(
              'No results found.  Start/End dates are invalid and return no data.'
            );
          }
        }

        this.startingNum = 0;
        this.nextNum = 20;
        this.dataSource.data = this.lspEdiError.slice(
          this.startingNum,
          this.nextNum
        ) as LspEdiErrorTx[];

        this._AuthService.getUser().subscribe((data) => {
          var email = data.email;
          this._appInsightsService.logEvent(
            `LSP EDI Error Report ran successfully between ${startDate} and ${endDate}`,
            {
              'Email Id': email
            }
          );
        });
      }),
      catchError((err) => {
        this._appInsightsService.logException(err, 1);
        this.isDataFound = false;
        console.log('Handling error locally and rethrowing it...', err);
        throw new Error(err.status + ' Error : ' + err.statusText);
      });
  }

  exportAsExcel() {
    this.isDataFound = true;
    this.isFetching = true;

    var startDateSelected = this.form.get('startDate').value;
    var endDateSelected = this.form.get('endDate').value;
    var start = this.form
      .get('startDate')
      .setValue(formatDate(startDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var end = this.form
      .get('endDate')
      .setValue(formatDate(endDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var startDateSelectedFormatted = this.form
      .get('startDate')
      .value.toString()
      .substring(0, 10);
    var endDateSelectedFormatted = this.form
      .get('endDate')
      .value.toString()
      .substring(0, 10);

    this._LspEdiErrorReportService
      .getLspEdiErrorExcel(
        startDateSelectedFormatted,
        endDateSelectedFormatted
      )
      .subscribe((response: any) => {
        this.dataIssues = response;
        this.isFetching = false;

        this.getExcelExport(this.dataIssues);
      });
  }

  getExcelExport(issues: any) {
    try {
      const workSheet = XLSX.utils.json_to_sheet(this.dataIssues, { header: [] });
      for(var i=0; i <= this.dataIssues.length; i++)
      {
        var v = 'K'+(i+1);
        delete(workSheet[v]);

      }
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
      XLSX.writeFile(workBook, 'lsp-edi-error-report.xlsx');

      this._AuthService.getUser().subscribe((data) => {
        var email = data.email;
        this._appInsightsService.logEvent(
          `LSP EDI Error Report exported successfully`,
          {
            'Email Id': email
          }
        );
      });
    } catch (e) {
      this._appInsightsService.logException(e.message, 1);
      console.error('Handling error locally and rethrowing it...', e.message);
      throw new Error(e);
    };
  }

  onCopyJsonToClipboard(housebill: string) {
    var startDateSelected = this.form.get('startDate').value;
    var endDateSelected = this.form.get('endDate').value;
    var start = this.form
      .get('startDate')
      .setValue(formatDate(startDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var end = this.form
      .get('endDate')
      .setValue(formatDate(endDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var startDateSelectedFormatted = this.form
      .get('startDate')
      .value.toString()
      .substring(0, 10);
    var endDateSelectedFormatted = this.form
      .get('endDate')
      .value.toString()
      .substring(0, 10);

    this._LspEdiErrorReportService.getPayload(startDateSelectedFormatted, endDateSelectedFormatted, housebill).subscribe((response: any) => {
      var element = document.createElement('a');
      const blob = new Blob([response.payload], { type: 'text' });
      element.href = window.URL.createObjectURL(blob);
      element.download = housebill + ' payload';
      document.body.appendChild(element);
      element.click();

      document.body.removeChild(element);
      this._AuthService.getUser().subscribe((data) => {
        var email = data.email;
        this._appInsightsService.logEvent(
          `LSP EDI Error Report JSON Object downloaded successfully`,
          {
            'Email Id': email
          }
        );
      });
    }),
    catchError((err) => {
      this._appInsightsService.logException(err, 1);
      console.log('Handling error locally and rethrowing it...', err);
      throw new Error(err);
    });
  }

  onDownloadEDI(fileName: string) {
    this._LspEdiErrorReportService
      .onDownloadEDI(fileName)
      .subscribe((response: any) => {
        let streamArray = response.stream;
        let fileName = response.fileName;
        var decodedString = atob(streamArray);
        var decodedFileName = atob(fileName);

        if (decodedString == '' && decodedFileName == '') {
          this.isRawFileFound = false;
          console.log(
            'No EDI file found in blob storage. Check inbound and inbound-archive storage containers.'
          );
        } else {
          this.isRawFileFound = true;
          var element = document.createElement('a');
          const blob = new Blob([decodedString], { type: 'text' });
          element.href = window.URL.createObjectURL(blob);
          element.download = decodedFileName;
          document.body.appendChild(element);
          element.click();

          document.body.removeChild(element);

          this._AuthService.getUser().subscribe((data) => {
            var email = data.email;
            this._appInsightsService.logEvent(
              `LSP EDI Error Report EDI file downloaded successfully`,
              {
                'Email Id': email
              }
            );
          });
        }
      }),
      catchError((err) => {
        this._appInsightsService.logException(err, 1);
        console.log('Handling error locally and rethrowing it...', err);
        throw new Error(err);
      });
  }

  showEntityStatus(value: any) {
    if (value.housebill == '' || null || value.housebill == 'Unknown') {
      return;
    } else {
      console.log(value);
      let housebill = value.housebill;
      let tp = value.tr;
      var url = '/reporting/entity-status?entityId=' + housebill;
      window.open(url, '_blank');

      this._AuthService.getUser().subscribe((data) => {
        var email = data.email;
        this._appInsightsService.logEvent(
          `Redirected to Entity Status successfully`,
          {
            'Email Id': email
          }
        );
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
